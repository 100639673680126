import React from 'react'
import Layout from '../../components/layout'
import Helmet from 'react-helmet'
import {
  Section,
  Row,
  Col,
  Container,
  Responsive,
} from '../../components/containers/Layout'
import { PostPreview, PostPreview2 } from '../../components/blog/PostPreview'

const Blog = () => (
  <Layout>
    <Section style={{
      backgroundColor: 'white'
    }}>
      <Container style={{maxWidth: 620}}>
        <Col>
        <PostPreview
          image={'dog.jpeg'}
          title={'How Food Recognition Will Change The World'}
          body={
            "Use our state of the art image recognition models to analyze what your users eat, " +
            "determine what's trending and make your content easier to find. " +
            "Get unmatched recognition accuracy with models that use a user's eating habits " +
            "to refine predictions and automatically learn to recognize past meals."
          }
        />
        <PostPreview2
          image={'photo-1511466422904-ecdbc68407a1.jpeg'}
          title={'Stopping A Leaky Bucket With AI'}
          body={
            "Use our state of the art image recognition models to analyze what your users eat, " +
            "determine what's trending and make your content easier to find. " +
            "Get unmatched recognition accuracy with models that use a user's eating habits " +
            "to refine predictions and automatically learn to recognize past meals."
          }
        />


          <PostPreview
          image={'full-table-2.jpeg'}
          title={'Semantics of Food'}
          body={
            "Use our state of the art image recognition models to analyze what your users eat, " +
            "determine what's trending and make your content easier to find. " +
            "Get unmatched recognition accuracy with models that use a user's eating habits " +
            "to refine predictions and automatically learn to recognize past meals."
          }
        />

          <PostPreview
          image={'man-cafe.jpeg'}
          title={"Why Food Recognition Is A Hard That Is Worth Solving"}
          body={
            "Products, home made meals, snacks, combinations" +
            "Use our state of the art image recognition models to analyze what your users eat, " +
            "determine what's trending and make your content easier to find. " +
            "Get unmatched recognition accuracy with models that use a user's eating habits " +
            "to refine predictions and automatically learn to recognize past meals."
          }
        />

          <PostPreview
          image={'fancycrave-520422-unsplash.jpg'}
          title={'How We Built a Product Digitization Pipeline'}
          body={
            "Use our state of the art image recognition models to analyze what your users eat, " +
            "determine what's trending and make your content easier to find. " +
            "Get unmatched recognition accuracy with models that use a user's eating habits " +
            "to refine predictions and automatically learn to recognize past meals."
          }
        />

          <PostPreview
          image={'full-table-2.jpeg'}
          title={'Building a Semantic Search Engine With Deep Learning'}
          body={
            "Use our state of the art image recognition models to analyze what your users eat, " +
            "determine what's trending and make your content easier to find. " +
            "Get unmatched recognition accuracy with models that use a user's eating habits " +
            "to refine predictions and automatically learn to recognize past meals."
          }
        />

        </Col>
      </Container>
    </Section>
  </Layout>
)

export default Blog
